/* Vendor imports */
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
/* App imports */
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import style from "./index.module.less";

export const aboutPropTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    flagIt: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    flagEn: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    skillIcons: PropTypes.object.isRequired,
    toolIcons: PropTypes.object.isRequired,
  }),
};

class About extends React.Component {
  static propTypes = aboutPropTypes;

  render() {
    let { profilePhoto, bclLogoIcon } = this.props.data;
    return (
      <Layout logo={bclLogoIcon}>
        <SEO
          title="About"
          description="A brief summary of the game studio"
          path="about"
        />
        <div className={style.container}>
          <div className={style.photo}>
            <Img fluid={profilePhoto.childImageSharp.fluid} />
          </div>
          <div className={style.content}>
            <h1>About us</h1>
            <p>
              Our studio Big City Lab has developed casual projects for
              BigFishGames since 2018. Three years later we decided to make a
              game that we could enjoy ourselves. Inspired by Breaking Bad and
              Ozark TV shows, we came up with the idea of our first indie game.
              It is a story-driven economic strategy game with management
              elements. Welcome to Goodland is planned to be released on Steam
              in 2023. The studio founders Andrey and Denis, whose professional
              work in finance influenced the choice of game mechanics and
              setting (but we have no experience with money laundering and
              working for Mexican cartels!).
            </p>
            <p>
              Since the first day, our teams have been fully remote and spread
              from Armenia to London. There are about 16 people in casual teams
              and 4 in indie team.
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  {
    bclLogoIcon: file(name: { eq: "bcl-logo-indie" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 42, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    profilePhoto: file(name: { eq: "dev-gamm" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
// Use to set specific icons names
export const iconsNameMap = {
  css: "CSS",
  html: "HTML",
  jquery: "JQuery",
  nodejs: "Node.js",
  vuejs: "Vue.js",
  gruntjs: "Grunt.js",
};

export default About;
